import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'layouts/index'
import Seo from 'components/seo'
import GotoDemoHero from 'components/hero/gotoDemo'
import { marketplaceFeatures } from '/static/data/solution.static'

const PowerUpsPage = () => {
  const MARKET_PLACE_FEATURES = marketplaceFeatures()
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Power-Ups marketplace - Mlytics',
            metaDescription:
              'Activate the best CDNs such as Akamai and Cloudflare with just a few clicks. Save time on CDN sourcing, implementation, testing, and launch.',
            metaUrl: 'https://www.mlytics.com/features/power-ups/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col md:flex-row gap-5 pb-32 pt-24">
          <div className="flex-1">
            <h1 className="font-normal mb-5 text-primary-400 text-lg">{t('marketplacePage.section1.subtitle')}</h1>
            <h2 className="leading-snug text-primary-500 text-4xl lg:text-5xl">
              {t('marketplacePage.section1.title')}
            </h2>
            <p className="py-10 text-xl text-gray-500">{t('marketplacePage.section1.description')}</p>
            <div className="flex flex-col lg:flex-row gap-3 my-1">
              <Link to="/sign-up" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                {t('button.freeTrial.0')}
              </Link>
              <Link
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-ghost btn-orange inline-block"
                style={{ width: 208 }}
              >
                {t('button.getDemo.0')}
              </Link>
            </div>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <StaticImage
              src="https://www.mlytics.com/wp-content/uploads/2022/10/Power-Ups_marketplace_hero_01.webp"
              alt={t('marketplacePage.section1.subtitle')}
              style={{ height: 'auto', maxWidth: '550px' }}
            />
          </div>
        </section>
        <section className="bg-primary-500 px-4 py-20">
          <div className="container">
            <h2 className="text-center text-white text-4xl">{t('marketplacePage.section2.title')}</h2>
            <p className="leading-7 m-auto max-w-[800px] pt-6 text-center text-white">
              {t('marketplacePage.section2.description')}
            </p>
          </div>
        </section>
        <section className="bg-primary-500/5 py-20">
          <div className="container grid grid-cols-1 lg:grid-cols-3 gap-5">
            {MARKET_PLACE_FEATURES.map((feature) => (
              <div key={feature.title} className="text-center">
                <img src={feature.imageSrc} alt={t(feature.title)} className="mx-auto" />
                <h2 className="text-primary-500 text-2xl">{t(feature.title)}</h2>
                <p className="my-5 text-gray-700">{t(feature.content)}</p>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://static.mlytics.com/images/website/marketplace_activate_cdn.svg"
                alt={t('marketplacePage.section4.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('marketplacePage.section4.subtitle')}</div>
              <h2 className="text-3xl">{t('marketplacePage.section4.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('marketplacePage.section4.description')}</p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://www.mlytics.com/wp-content/uploads/2021/11/Power-Ups_marketplace_2_01.svg"
                alt={t('marketplacePage.section5.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('marketplacePage.section5.subtitle')}</div>
              <h2 className="text-3xl">{t('marketplacePage.section5.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">
                {t('marketplacePage.section5.description.0')}
                <br />
                <br />
                {t('marketplacePage.section5.description.1')}
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container flex flex-col md:flex-row-reverse gap-10 py-20">
            <div className="flex flex-1 items-center overflow-hidden">
              <img
                src="https://static.mlytics.com/images/website/marketplace_no_contracts_required.svg"
                alt={t('marketplacePage.section6.title')}
                style={{ height: 'auto', maxWidth: '550px' }}
              />
            </div>
            <div className="flex-1">
              <div className="font-black text-lg text-primary-500">{t('marketplacePage.section6.subtitle')}</div>
              <h2 className="text-3xl">{t('marketplacePage.section6.title')}</h2>
              <p className="pb-7 pt-2 text-gray-500">{t('marketplacePage.section6.description')}</p>
            </div>
          </div>
        </section>
        <GotoDemoHero />
      </React.Fragment>
    </Layout>
  )
}

export default PowerUpsPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
